import { makeObservable, configure, observable, action, computed } from 'mobx'
import { axiosPrivate } from '../modules/axios/axiosPrivate'
import { hiddenCoef, isFilterTime, formatCoef } from '../helpers/index'
import { currenciesList, sortBkInStatRow } from '../constants'
import { getForksByEventId } from '../api'

configure({ enforceActions: 'observed' })

class SportEvent {
  rootStore = null

  loadingStatus = true

  eventsArr = []
  sourceEventsArr = []

  filterProfit = this.getFiltersFromLocalStorage()

  favoriteArr = []

  idForShowCalculator = null

  fullSumBet = 100

  calculatorValues = []

  currentCurrency = 'RUB'
  currenciesList = currenciesList

  showButtonForks = true

  eventID = null
  eventMainData = {}

  stateChangeArray = 0

  isPrematch = true

  constructor(rootStore) {
    makeObservable(this, {
      loadingStatus: observable,
      eventsArr: observable,
      sourceEventsArr: observable,
      favoriteArr: observable,
      idForShowCalculator: observable,
      fullSumBet: observable,
      calculatorValues: observable,
      currenciesList: observable,
      currentCurrency: observable,
      showButtonForks: observable,
      eventMainData: observable,
      eventID: observable,
      stateChangeArray: observable,
      filterProfit: observable,
      isPrematch: observable,
      place: computed,
      changeTypeBets: action,
      setCalculator: action,
      updateSumBet: action,
      getEventsFromServer: action,
      setEventsData: action,
      setCalculatorValues: action,
      changeSumBetRow: action,
      changeKefBetRow: action,
      changeCurrency: action,
      sortEvents: action,
      changeFavorite: action,
      updateArrayEvents: action,
      getForksForEventFromServer: action,
      changeStateShowButtonForks: action,
      setInfoForEvent: action,
      saveEventId: action,
      filterProfitEventsArr: action,
      filterWholeRatesEventsArr: action,
      getFiltersFromLocalStorage: action,
      clearIsNew: action,
      clearIsRemoved: action,
      resetCoefficients: action,
      changeFilterProfit: action
    })

    this.rootStore = rootStore
  }

  get place() {
    return this.isPrematch ? 'line' : 'live'
  }

  checkIncludesFilter(fork) {
    const { sportName, bets, profitable, eventDateTime } = fork

    const {
      sportName: filterSport, // виды спорта
      timeEvent: filterTimeEvent, // время до начала события
      bks: filterBks, // букмекеры
      isFraction: filterIsFraction, // скрыть целые
      profit: filterProfit, // профитность вилки > x%
      showAsian: filterShowAsian, // скрыть азиатские
      showMain: filterShowMain, // основные
      showHandicap: filterShowHandicap, // гандикапы
      showTotal: filterShowTotal, // тоталы
      showIndividualTotal: filterShowIndividualTotal // инд. тоталы
    } = this.rootStore.Filter.currentFilter

    if (profitable < filterProfit)
      return { status: false, reason: `Не подходит ПРОФИТ` }

    if (filterSport.length && !filterSport.includes(sportName))
      return { status: false, reason: `Не подходит ВИД СПОРТА` }

    if (filterIsFraction && bets[0].coefParam % 100 === 0)
      return { status: false, reason: `Не подходит ДРОБНОСТЬ` }

    if (filterShowAsian && bets[0].coefTypeParam === 'Азиатская')
      return { status: false, reason: 'Азиатская фора должна скрываться' }

    if (filterBks.length === 1)
      return { status: false, reason: `Выбрана 1 контора` }

    if (
      filterBks.length &&
      (!filterBks.includes(bets[0].bk) || !filterBks.includes(bets[1].bk))
    )
      return { status: false, reason: `Не подходят КОНТОРЫ` }

    if (
      !filterShowMain &&
      ['01', '1X', '02', 'X2', '12', '0X'].includes(bets[0].coefType)
    )
      return { status: false, reason: `Не подходит тип ставки (ОСНОВНАЯ)` }

    if (!filterShowHandicap && ['Ф1', 'Ф2'].includes(bets[0].coefType))
      return { status: false, reason: `Не подходят тип ставки (ГАНДИКАП)` }

    if (!filterShowTotal && ['ТБ', 'ТМ'].includes(bets[0].coefType))
      return { status: false, reason: `Не подходят тип ставки (ТОТАЛ)` }

    if (
      !filterShowIndividualTotal &&
      ['ИТ1Б', 'ИТ1М', 'ИТ2Б', 'ИТ2М'].includes(bets[0].coefType)
    )
      return { status: false, reason: `Не подходят тип ставки (ИНД. ТОТАЛ)` }

    const filterTime = isFilterTime({
      value: filterTimeEvent,
      dateEvent: eventDateTime
    })

    if (!filterTime) return { status: false, reason: `Не подходит ВРЕМЯ` }

    return { status: true }
  }

  getPercentFork(arr) {
    return Number(
      arr.reduce((pre, cur) => {
        const coef = cur.newCoef ? cur.newCoef : cur.coef

        return (pre += 1 / coef)
      }, 0)
    )
  }

  getTotalBetAmount(arr) {
    return Number(
      arr.reduce((pre, cur) => {
        const sumBet = Number(cur.sumBet)

        return (pre += sumBet)
      }, 0)
    )
  }

  async getProfitableForkForEvent({ id }) {
    const filters = this.rootStore.Filter?.currentFilter

    const forks = await this.getDataFromServer({
      endpoint: '/bets',
      ...filters,
      eventId: id
    })
    const profitableFork =
      forks.length === 0
        ? null
        : forks.reduce((pre, cur) => {
            const preProfit = Number(pre?.profitable || 0)
            const curProfit = Number(cur?.profitable || 0)

            if (curProfit > preProfit) return cur
            return pre
          }, {})

    return profitableFork
  }

  async getDataFromServer({ endpoint, ...filter }) {
    try {
      const res = await axiosPrivate.post(endpoint, filter)
      const events = res.data.data || []
      return events
    } catch (e) {
      console.error(e)
      if (e.response?.status === 401) {
        this.rootStore.Auth.setSession(null)
      }
      return []
    }
  }

  changeStatusLoading(status) {
    this.loadingStatus = status
  }

  setCalculatorValues() {
    console.warn('1. Что то запустило калькулятор ...')

    const arrCalculator = this.calculatorValues

    if (arrCalculator.length > 0) {
      const percent = this.getPercentFork(arrCalculator)
      this.calculatorValues = arrCalculator.map((item) => {
        const { coef, currency } = item

        const sumBet = !coef
          ? hiddenCoef
          : ((1 / coef / percent) * this.fullSumBet).toFixed(2)
        const profit = !coef
          ? hiddenCoef
          : (coef * sumBet - this.fullSumBet).toFixed(2)

        return {
          coef: coef || hiddenCoef,
          sumBet: sumBet || hiddenCoef,
          currency,
          profit
        }
      })
    } else {
      const row = this.eventsArr.find(
        (item) => item.id === this.idForShowCalculator
      )
      if (row) {
        const percent = this.getPercentFork(row.bets)

        this.calculatorValues = row.bets.map((item) => {
          const coef = item.newCoef ? item.newCoef : item.coef
          const sumBet = !coef
            ? hiddenCoef
            : ((1 / coef / percent) * this.fullSumBet).toFixed(2)
          const profit = !coef
            ? hiddenCoef
            : (coef * sumBet - this.fullSumBet).toFixed(2)

          return {
            coef: coef || hiddenCoef,
            sumBet: sumBet || hiddenCoef,
            currency: this.currentCurrency,
            profit
          }
        })
      }
    }
  }

  calculateFork() {
    console.warn('2. Что то запустило калькулятор ...')

    const arrCalculator = this.calculatorValues
    if (arrCalculator.length > 0) {
      this.calculatorValues = arrCalculator.map((item) => {
        const { coef, currency, sumBet } = item
        const profit = !coef
          ? hiddenCoef
          : (coef * sumBet - this.fullSumBet).toFixed(2)

        const _coef = coef === null ? hiddenCoef : coef
        const _sumBet = sumBet === null ? hiddenCoef : sumBet

        return { coef: _coef, sumBet: _sumBet, currency, profit }
      })
    }
  }

  changeSumBetRow(data) {
    const { index, val } = data
    this.calculatorValues[index].sumBet = val

    const total = this.getTotalBetAmount(this.calculatorValues)
    this.fullSumBet = formatCoef(total)

    this.calculateFork()
  }

  changeKefBetRow(data) {
    const { index, val } = data
    this.calculatorValues[index].newCoef
      ? (this.calculatorValues[index].newCoef = val)
      : (this.calculatorValues[index].coef = val)

    this.setCalculatorValues()
  }

  setCalculator(id) {
    this.calculatorValues = [] // Сбрасываем значения для строк калькулятора
    this.fullSumBet = 100 // Сбрасываем общую сумму для калькулятора

    if (this.idForShowCalculator === id) {
      this.idForShowCalculator = null // Чистим ID калькулятора
      this.eventsArr = this.eventsArr.filter((item) => !item.isRemoved)
    } else {
      this.idForShowCalculator = id
      this.setCalculatorValues()
    }
  }

  changeFavorite(id) {
    if (this.favoriteArr.includes(id)) {
      this.favoriteArr = this.favoriteArr.filter((item) => item !== id)
    } else {
      this.favoriteArr.push(id)
    }
  }

  updateSumBet(sum) {
    this.fullSumBet = sum
    this.setCalculatorValues()
  }

  sortEvents(events) {
    this.stateChangeArray = new Date().getTime()

    for (let event of events) {
      event.bets.sort((a, b) => {
        const aBk = a.bk
        const bBk = b.bk

        if (aBk > bBk) return 1
        if (aBk < bBk) return -1
        return 0
      })
    }

    return events.sort((a, b) => {
      const percentA = Number(a.profitable)
      const percentB = Number(b.profitable)

      return percentB - percentA
    })
  }

  async getEventsFromServer(filters = {}) {
    const currentFilter = this.rootStore.Filter?.currentFilter || {}
    const isAuth = !!this.rootStore.Auth.authUser

    // Чтобы не делать лишние запросы
    if (isAuth && currentFilter.default) return

    filters = { ...currentFilter, ...filters }

    this.changeStatusLoading(true)
    this.eventsArr = []
    this.eventID = null

    const authUser = this.rootStore.Auth.authUser

    if (authUser) {
      let endpoint = '/profitable_bets'
      let profitableBets = await this.getDataFromServer({
        endpoint,
        ...filters
      })
      endpoint = '/auth/saveUserFilters'
      await this.getDataFromServer({ endpoint, ...filters })
      this.setEventsData(this.sortEvents(profitableBets))
    } else {
      const endpoint = '/profitable_bets_no_auth'
      const events = await this.getDataFromServer({ endpoint, ...filters })
      this.setEventsData(this.sortEvents(events))
    }

    console.log('Get PROFITABLE FORKS')
  }

  async getStatistics(filters = {}) {
    const currentFilter = this.rootStore.Filter?.currentFilter || {}
    const isAuth = !!this.rootStore.Auth.authUser

    // Чтобы не делать лишние запросы
    if (isAuth && currentFilter.default) return

    filters = { ...currentFilter, ...filters }

    const authUser = this.rootStore.Auth.authUser

    if (authUser) {
      const endpoint = '/bets/count'
      const { profitableBets, countEventsBk, countMatchedEvents } =
        await this.getDataFromServer({ endpoint, ...filters })
      this.calcStatistics({
        profitableBets,
        countEventsBk,
        countMatchedEvents
      })
    }
  }

  async getForksForEventFromServer({ id, place }) {
    console.log('ЗАГРУЗКА ВИЛОК ПО СОБЫТИЮ')

    const filters = this.rootStore.Filter?.currentFilter

    this.changeStatusLoading(true)
    this.setEventsData([])

    const response = await getForksByEventId(id, filters)
    this.setEventsData(this.sortEvents(response.data))
    this.setInfoForEvent()
  }

  setEventsData(values) {
    this.changeStatusLoading(false)

    // Пока не убираем исходные данные, вдруг фильтрация на клиенте понадобится
    this.sourceEventsArr = values
    this.eventsArr = values

    this.stateChangeArray = new Date().getTime()
    this.calcStatistics({ profitableBets: this.eventsArr })
  }

  changeCurrency(val) {
    this.currentCurrency = val
  }

  async updateArrayEvents(payload) {
    const { type, ids, profitableBets, statistics } = payload

    if (type === 'DELETE') {
      ids.map((item) => this.deleteFork(item))
    } else if (type === 'INSERT' || type === 'UPDATE') {
      profitableBets.map((item) => this.addFork(item, type))
    }

    console.log('ИЗМЕНЕНИЕ СПИСКА СОБЫТИЙ', payload)

    this.filterProfitEventsArr(this.filterProfit[this.place])

    const arrForks = this.eventsArr.filter((item) => !item.isRemoved)

    if (statistics) {
      const { countEventsBk, countMatchedEvents } = statistics
      this.calcStatistics({
        countEventsBk,
        countMatchedEvents,
        profitableBets: arrForks
      })
    } else {
      this.calcStatistics({ profitableBets: arrForks })
    }
  }

  async deleteFork({ id, eventId }) {
    let event = this.sourceEventsArr.find((item) => item.eventId === eventId)
    let index = this.sourceEventsArr.findIndex((item) => item.id === id)

    if (!event) return

    if (event.id === id) {
      // самая валидная вилка
      delete event.isNew

      if (this.favoriteArr.includes(id)) this.changeFavorite(id)

      if (event.countBets === 1) {
        event.isRemoved = true

        let deleteTime =
          this.rootStore.Filter.currentFilter.deleteTimeBets?.value || 3
        setTimeout(
          () => this.sourceEventsArr.splice(index, 1),
          deleteTime * 1000
        )
        console.log(`Удаление ПОСЛЕДНЕЙ вилки по событию: ${event}`)
      } else {
        let profitableFork = await this.getProfitableForkForEvent({
          id: eventId
        })
        if (profitableFork) {
          profitableFork.countBets = event.countBets
          profitableFork.isNew = true
          event = { ...profitableFork }
          console.log(`Удаление САМОЙ ВАЛИДНОЙ ВИЛКИ: ${profitableFork}`)
        }
      }
    }
    
    event.countBets--
    console.log(`(-1) Уменьшаем количество вилок по событию: ${event}`)
    let eventIndex = this.sourceEventsArr.findIndex(
      (item) => item.eventId === eventId
    )
    this.sourceEventsArr[eventIndex] = event
  }

  async addFork(profitableBet, type) {
    if (this.eventID && this.eventID !== profitableBet?.eventId)
      return console.log(`Вилка по другому событию: ${profitableBet?.eventId}`)

    if (!profitableBet) 
      return console.log('Нет информации по вилке!')

    if (profitableBet.place !== this.place)
      return console.log(`Вилка из другого ТИПА ВРЕМЕНИ: ${profitableBet.id}`)

    // Временное решение для проверки по фильтрам
    const { status: isAccessFilter, reason } =
      this.checkIncludesFilter(profitableBet)
    if (!isAccessFilter) return console.log(reason)

    // Делаем вилку с ХХХХ, так как юзер не авторизован.
    // if (!this.rootStore.Auth.userId) {
    //   profitableBet.bets = profitableBet.bets.map((item) => {
    //     item.coef = item.coefParam = item.coefType = item.newCoef = null
    //     return item
    //   })
    // }

    const indexEvent = this.sourceEventsArr.findIndex(
      (item) => item.eventId === profitableBet.eventId
    )

    if (indexEvent >= 0) {
      // Обновляем текущее событие
      const indexFork = this.sourceEventsArr.findIndex(
        (item) => item.id === profitableBet.id
      )
      if (
        profitableBet.profitable > this.sourceEventsArr[indexEvent].profitable
      ) {
        if (indexFork >= 0) {
          // Должны обновить текущую вилку
          const bets = profitableBet.bets
          const curEvent = this.sourceEventsArr[indexEvent]

          // Чистим информацию об удалении вилки и делаем её новой
          if (curEvent.isRemoved) delete curEvent.isRemoved
          curEvent.isNew = true

          const findedBets = curEvent.bets

          // Обновляем кэфы
          for (let row of findedBets) {
            for (let bet of bets) {
              if (row.bk === bet.bk) {
                row.newCoef = bet.coef
                break
              }
            }
          }

          curEvent.profitable = profitableBet.profitable
          curEvent.sureBetDateTime = profitableBet.sureBetDateTime

          this.sourceEventsArr.splice(indexEvent, 1)
          this.sourceEventsArr.push(curEvent)

          console.log(`Обновили данные по САМОЙ ВАЛИДНОЙ вилке:`, curEvent, type)
        } else {
          const curEvent = this.sourceEventsArr[indexEvent]
          this.sourceEventsArr.splice(indexEvent, 1)
          profitableBet.isNew = true
          profitableBet.countBets = curEvent.countBets
          if (type === 'INSERT') {
            profitableBet.countBets++
            console.log(`(+1) НОВАЯ САМАЯ ВАЛИДНАЯ ВИЛКА ПО СОБЫТИЮ: ${profitableBet}`)
          } 
          this.sourceEventsArr.push(profitableBet)
        }
      } else {
        if (indexFork >= 0) {
          // проверяем самую профитную вилку по событию
          let profitableFork = await this.getProfitableForkForEvent({
            id: profitableBet.eventId
          })
          if (profitableFork) {
            const curEvent = this.sourceEventsArr[indexEvent]
            this.sourceEventsArr.splice(indexEvent, 1)
            profitableFork.isNew = true
            profitableBet = { ...profitableFork }
            profitableBet.countBets = curEvent.countBets
            this.sourceEventsArr.push(profitableBet)
            console.log(
              `Обновление самой валидной вилки по событию: ${profitableBet}`
            )
          }
        } else {
          // пришла не самая профитная вилка
          if (type === 'INSERT') { 
            this.sourceEventsArr[indexEvent].countBets++
            console.log(`(+1) Добавили НОВУЮ ВИЛКУ по событию`, profitableBet, type)
          }
        }
      }
    } else {
      // Добавляем новое событие
      profitableBet.isNew = true
      profitableBet.countBets = 1
      this.sourceEventsArr.push(profitableBet)

      console.log(`(+1) Добавили НОВОЕ событие`, profitableBet, type)
    }

    this.sourceEventsArr = this.sortEvents(this.sourceEventsArr)
  }

  changeStateShowButtonForks(status) {
    this.showButtonForks = status
  }

  setInfoForEvent() {
    const event = this.eventsArr.find((item) => item.eventId === this.eventID)

    if (event) {
      const bet = event.bets[0]

      this.eventMainData = {
        eventDateTime: event.eventDateTime,
        eventName: bet.eventName,
        nameTournament: bet.nameTournament
      }
    }
  }

  saveEventId(id) {
    this.eventID = id
  }

  calcStatistics({
    profitableBets = null,
    countEventsBk = [],
    countMatchedEvents = []
  }) {
    let countForks,
      initialForks = this.rootStore.Filter.filterParams[0].value
    if (typeof profitableBets === 'number') {
      // в первый раз при получении статистики записываем кол-во вилок
      if (initialForks === '...') countForks = profitableBets
    } else {
      countForks = profitableBets
        ? profitableBets.reduce((pre, cur) => {
            return (pre += cur?.countBets || 1)
          }, 0)
        : null
    }

    const countEventsBkValue = countEventsBk.reduce((pre, cur) => {
      return (pre += cur.countEvents)
    }, 0)

    countEventsBk.sort((a, b) => {
      const aBk = a.bk
      const bBk = b.bk

      return sortBkInStatRow[aBk] > sortBkInStatRow[bBk] ? 1 : -1
    })

    const moreEventsBkValue = countEventsBk
      .map(({ bk, countEvents }) => `${bk}: <b>${countEvents}</b>`)
      .join(', ')

    const countMatchedEventsValue = countMatchedEvents.reduce((pre, cur) => {
      return (pre += cur.countMatchedEvents)
    }, 0)

    const params = []

    if (countForks !== undefined)
      params.push({ name: 'forks', value: countForks })
    if (countEventsBk.length)
      params.push({
        name: 'events',
        value: countEventsBkValue,
        additional: moreEventsBkValue
      })
    if (countMatchedEvents.length)
      params.push({ name: 'matches', value: countMatchedEventsValue })

    this.rootStore.Filter.setFilterParams(params)
  }

  filterProfitEventsArr(profit) {
    this.eventsArr = this.sourceEventsArr.filter(
      (item) => item.profitable > profit
    )

    this.filterWholeRatesEventsArr()
  }

  filterWholeRatesEventsArr() {
    const filterIsFraction = this.rootStore.Filter.currentFilter.isFraction

    if (filterIsFraction) {
      this.eventsArr = this.eventsArr.filter((item) => {
        const bets = item.bets
        const status =
          bets.filter((item) => !item.coefParam || item.coefParam % 100 !== 0)
            .length > 0
        return status
      })
    }
  }

  async changeTypeBets(status) {
    this.isPrematch = status
  }

  getFiltersFromLocalStorage() {
    const defaultFilters = { live: 0, line: 0 }

    const filters = localStorage.getItem('filterProfit')
    if (filters) {
      const filtersParsed = JSON.parse(filters)
      if (typeof filtersParsed === 'object') {
        return filtersParsed
      } else {
        localStorage.removeItem('filterProfit')
      }
    }

    return defaultFilters
  }

  clearIsNew(id) {
    this.sourceEventsArr = this.sourceEventsArr.map((item) => {
      if (item.id === id) {
        delete item.isNew
      }

      return item
    })

    this.filterProfitEventsArr(this.filterProfit[this.place])
  }

  // Очищаем статус удаленного
  clearIsRemoved(id) {
    this.sourceEventsArr = this.sourceEventsArr.map((item) => {
      if (item.id === id) {
        delete item.isRemoved
      }

      return item
    })

    this.filterProfitEventsArr(this.filterProfit[this.place])
  }

  resetCoefficients() {
    this.calculatorValues = []
    this.fullSumBet = 100

    this.setCalculatorValues()
  }

  calculateMinutes(date) {
    if (!date) return 0
    return (new Date() - new Date(date)) / (1000 * 60)
  }

  // Изменение значения фильтров + запрос данных по фильтрам с сервера
  async changeFilterProfit(profit) {
    if (!profit) profit = 0

    if (this.filterProfit[this.place] === Number(profit).toFixed(2)) return

    this.filterProfit[this.place] = Number(profit).toFixed(2)
    localStorage.setItem('filterProfit', JSON.stringify(this.filterProfit))
  }
}

export default SportEvent
